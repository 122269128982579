<script setup lang="ts">
import { ref, computed } from "@vue/reactivity";
import { RedeemFAQData } from "../../api.generated/scion";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
  faq: RedeemFAQData;
  primaryColour: string;
  productIsCanadian: boolean;
}>();

const isExpanded = ref<boolean>(false);

const questionsMapper = computed(
  (): {
    [key: string]: string;
  } => {
    return {
      can_an_egift_be_reissued_if_lost_or_stolen: t(
        "message.can_an_egift_be_reissued_if_lost_or_stolen"
      ),
      can_more_than_one_digital_gift_card_be_used_on_a_single_purchase: t(
        "message.can_more_than_one_digital_gift_card_be_used_on_a_single_purchase"
      ),
      kann_der_geschenk_gutschein_in_allen_ländern_eingelöst_werden: t(
        "message.can_the_egift_be_redeemed_in_all_countries"
      ),
      kann_ein_egift_mit_anderen_gutscheinen_oder_rabatten_kombiniert_werden: t(
        "message.can_an_egift_be_combined"
      ),
      can_the_egift_be_redeemed_in_all_stores: t(
        "message.can_the_egift_be_redeemed_in_all_stores"
      ),
      can_mobile_device_be_used_to_scan_the_egift_in_store: t(
        "message.can_mobile_device_be_used_to_scan_the_egift_in_store"
      ),
      can_an_egift_be_combined_with_other_coupons_voucher_or_discounts: t(
        "message.can_an_egift_be_combined"
      ),
      lecadeau_peut_il_être_utilisé_dans_tous_les_pays: t(
        "message.can_the_egift_be_redeemed_in_all_countries"
      ),
      can_the_egift_be_redeemed_in_all_countries: t(
        "message.can_the_egift_be_redeemed_in_all_countries"
      ),
      can_a_digital_gift_card_be_reissued_if_lost_or_stolen: t(
        "message.can_a_digital_gift_card_be_reissued_if_lost_or_stolen"
      ),
      kann_pro_einkauf_mehr_als_ein_geschenk_gutschein_eingelöst_werden: t(
        "message.can_more_than_one_egift_be_used_in_a_single_purchase"
      ),
      can_the_leftover_balance_on_my_digital_gift_card_be_used_for_future_purchases:
        t(
          "message.can_the_leftover_balance_on_my_digital_gift_card_be_used_for_future_purchases"
        ),
      est_ce_que_plus_dun_cadeau_électronique_peut_être_utilisé_sur_un_seul_achat:
        t("message.can_more_than_one_egift_be_used_in_a_single_purchase"),
      can_mobile_device_be_used_to_scan_the_digital_gift_card_in_a_restaurant:
        t(
          "message.can_mobile_device_be_used_to_scan_the_digital_gift_card_in_a_restaurant"
        ),
      kann_der_geschenk_gutschein_erneut_ausgestellt_werden_wenn_er_verloren_geht_oder_gestohlen_wird:
        t("message.can_an_egift_be_reissued_if_lost_or_stolen"),
      si_lecadeau_ne_couvre_pas_le_montant_de_lachat_puis_je_payer_la_différence_avec_un_autre_mode_de_paiement:
        t(
          "message.egift_can_i_pay_the_difference_with_alternative_form_of_payment"
        ),
      un_cadeau_électronique_peut_il_être_réémis_en_cas_de_perte_ou_de_vol: t(
        "message.can_an_egift_be_reissued_if_lost_or_stolen"
      ),
      if_the_egift_does_not_cover_the_purchase_amount_can_i_pay_the_difference_with_an_alternate_form_of_payment:
        t(
          "message.egift_can_i_pay_the_difference_with_alternative_form_of_payment"
        ),
      le_solde_restant_de_mon_cadeau_électronique_peut_il_être_utilisé_pour_de_futurs_achats:
        t(
          "message.can_the_leftover_balance_on_my_egift_be_used_for_future_purchases"
        ),
      if_the_digital_gift_card_does_not_cover_the_purchase_amount_can_i_pay_the_difference_with_an_alternate_form_of_payment:
        t(
          "message.digital_card_can_i_pay_the_difference_with_alternative_form_of_payment"
        ),
      can_more_than_one_egift_be_used_on_a_single_purchase: t(
        "message.can_more_than_one_egift_be_used_in_a_single_purchase"
      ),
      kann_ich_das_restguthaben_meines_geschenk_gutscheins_für_künftige_einkäufe_nutzen:
        t(
          "message.can_the_leftover_balance_on_my_egift_be_used_for_future_purchases"
        ),
      can_the_digital_gift_card_be_redeemed_in_all_countries: t(
        "message.can_the_digital_gift_card_be_redeemed_in_all_countries"
      ),
      can_a_digital_gift_card_be_combined_with_other_coupons_vouchers_or_discounts:
        t("message.can_a_digital_gift_card_be_combined"),
      can_the_digital_gift_card_be_redeemed_in_all_restaurants: t(
        "message.can_the_digital_gift_card_be_redeemed_in_all_restaurants"
      ),
      can_a_digital_gift_card_be_combined_with_other_coupons_voucher_or_discounts:
        t("message.can_a_digital_gift_card_be_combined_voucher_in_singular"),
      lecadeau_peut_il_être_utilisé_dans_tous_les_magasins: t(
        "message.can_the_egift_be_redeemed_in_all_stores"
      ),
      un_e_cadeau_peut_il_être_combiné_avec_dautres_coupons_bons_dachat_ou_réductions:
        t("message.can_an_egift_be_combined"),
      can_the_leftover_balance_on_my_egift_be_used_for_future_purchases: t(
        "message.can_the_leftover_balance_on_my_egift_be_used_for_future_purchases"
      ),
      lappareil_mobile_peut_il_être_utilisé_pour_scanner_lecadeau_en_magasin: t(
        "message.can_mobile_device_be_used_to_scan_the_egift_in_store"
      ),
      falls_das_guthaben_des_geschenk_gutscheins_für_den_kaufbetrag_nicht_ausreicht_kann_ich_den_differenzbetrag_mit_einem_anderen_zahlungsmittel_begleichen:
        t(
          "message.egift_can_i_pay_the_difference_with_alternative_form_of_payment"
        ),
    };
  }
);
const formatTextToKey = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[^\w\s]/g, "") // Remove punctuation
    .replace(/\s+/g, "_"); // Replace spaces with underscores
};
const getTranslatedQuestion = (question: string) => {
  // Canadian products have backoffice translations
  if (props.productIsCanadian) {
    return question;
  }
  const key = formatTextToKey(question);
  return questionsMapper.value[key] || question;
};
</script>

<template>
  <div class="py-4">
    <div v-if="faq.isBooleanAnswer">
      <div class="flex items-center">
        <div class="mr-4 flex-1 font-normal">
          {{ getTranslatedQuestion(faq.question) }}
        </div>

        <div
          class="flex items-center self-start"
          :style="{ color: primaryColour }"
        >
          <svg
            v-if="faq.booleanAnswer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-10"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-10"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
    </div>

    <div v-else>
      <div
        @click="isExpanded = !isExpanded"
        class="flex cursor-pointer items-center"
      >
        <span class="mr-4 flex-1 font-normal">
          {{ getTranslatedQuestion(faq.question) }}
        </span>
        <svg
          v-if="!isExpanded"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-8 self-start"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-8 self-start"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          />
        </svg>
      </div>
      <div :class="[isExpanded ? 'block' : 'hidden', 'py-26 print:block']">
        {{ faq.rawAnswer }}
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { ref } from "vue";

export interface Tab {
  label: string;
  target: string;
  isActive: boolean;
}

const props = defineProps<{
  tabs: Tab[];
}>();

const tabs = ref<Tab[]>(props.tabs);

function toggleTo(selectedTab: Tab) {
  tabs.value.forEach((tab) => {
    tab.isActive = false;

    if (tab.target === selectedTab.target) {
      tab.isActive = true;
    }
  });
}
</script>

<template>
  <div class="print:hidden">
    <div class="border-b border-gray-200">
      <ul class="-mb-px flex flex-wrap text-center" role="tablist">
        <li
          v-for="tab in tabs"
          :key="tab.target"
          class="mr-6 last:mr-0"
          role="presentation"
        >
          <button
            @click="toggleTo(tab)"
            :id="`${tab.target}-tab`"
            :class="[
              'inline-block py-4',
              tab.isActive && 'border-b-2 border-gray-800 font-semibold',
            ]"
            type="button"
            role="tab"
            :aria-controls="tab.target"
            :aria-selected="tab.isActive"
          >
            {{ tab.label }}
          </button>
        </li>
      </ul>
    </div>
    <div>
      <div
        v-for="tab in tabs"
        :key="tab.target"
        :class="tab.isActive ? 'block' : 'hidden'"
      >
        <slot :name="tab.target"></slot>
      </div>
    </div>
  </div>

  <div class="hidden print:block" aria-hidden="true">
    <div v-for="tab in tabs" :key="tab.target">
      <div class="border-b border-gray-200">
        <ul class="-mb-px flex flex-wrap text-center" role="tablist">
          <li role="presentation">
            <button
              class="inline-block border-b-2 border-gray-800 py-4 font-semibold"
              type="button"
            >
              {{ tab.label }}
            </button>
          </li>
        </ul>
      </div>
      <div>
        <div>
          <slot :name="tab.target"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.how-to-text p {
  margin-bottom: 1rem;
}
.how-to-text p:last-child {
  margin-bottom: 0;
}
</style>

<script setup lang="ts">
import Markdown from "markdown-it";
import { onMounted } from "vue";

const props = defineProps<{
  redeemInstructions: string;
}>();

onMounted(() => {
  const md = new Markdown();
  const howTo = md.render(props.redeemInstructions);
  const contentContainer = document.getElementById("how-to-content");

  if (contentContainer) {
    contentContainer.innerHTML = howTo;
  }
});
</script>

<template>
  <div>
    <h2 class="mb-6 text-2xl font-semibold">{{ $t("message.how_to_use") }}</h2>
    <div id="how-to-content" class="how-to-text font-normal"></div>
  </div>
</template>

<style scoped>
:deep() .how-to-text p {
  margin-bottom: 1rem;
}
:deep() .how-to-text p:last-child {
  margin-bottom: 0;
}
</style>

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Where do we load the PIN from when showing to buyers?
 */
export enum ECodePINSourceEnum {
  NONE = "none",
  PIN = "pin",
  CVC2 = "cvc2",
}

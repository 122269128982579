/**
 * Determines whether to perform an action based on a given percentage chance.
 * If the percentage is not valid a console error will be logged and false will be returned.
 *
 * @param {number} percentage - The probability (between 0 and 1) that the action should be performed.
 * @returns {boolean} - Returns `true` if the action should be performed, otherwise `false`.
 */
export function splitter(percentage: number): boolean {
  if (isNaN(percentage) || percentage < 0 || percentage > 1) {
    console.error(
      "Invalid percentage value. It should be a number between 0 and 1."
    );
    return false;
  }

  const randomValue = Math.random();

  return randomValue < percentage;
}

<script setup lang="ts">
import { onMounted, onUnmounted, computed, ref } from "vue";
import { trackEvent } from "@two-ui/mixpanel";

const SAVOO_EXPERIMENT_MS_IMG_URL = import.meta.env
  .VITE_SAVOO_EXPERIMENT_MS_IMG_URL;

const SAVOO_EXPERIMENT_MS_DISCOUNTS_URL = import.meta.env
  .VITE_SAVOO_EXPERIMENT_MS_DISCOUNTS_URL;

const SAVOO_EXPERIMENT_MS_TITLE = import.meta.env
  .VITE_SAVOO_EXPERIMENT_MS_TITLE;

const SAVOO_EXPERIMENT_DELIVEROO_IMG_URL = import.meta.env
  .VITE_SAVOO_EXPERIMENT_DELIVEROO_IMG_URL;

const SAVOO_EXPERIMENT_DELIVEROO_DISCOUNTS_URL = import.meta.env
  .VITE_SAVOO_EXPERIMENT_DELIVEROO_DISCOUNTS_URL;

const SAVOO_EXPERIMENT_DELIVEROO_TITLE = import.meta.env
  .VITE_SAVOO_EXPERIMENT_DELIVEROO_TITLE;

const props = defineProps<{
  productCode: string;
  customerId: string;
  token: string;
}>();

const mdAndDown = ref(window.innerWidth <= 768);

const activeSavooLogoUrl = computed(() => {
  if (props.productCode === "MAS-GB") {
    return SAVOO_EXPERIMENT_MS_IMG_URL;
  } else if (props.productCode === "DROO-GB") {
    return SAVOO_EXPERIMENT_DELIVEROO_IMG_URL;
  }
  return "";
});
const activeSavooDiscountsUrl = computed(() => {
  if (props.productCode === "MAS-GB") {
    return SAVOO_EXPERIMENT_MS_DISCOUNTS_URL;
  } else if (props.productCode === "DROO-GB") {
    return SAVOO_EXPERIMENT_DELIVEROO_DISCOUNTS_URL;
  }
  return "";
});
const activeSavooTitle = computed(() => {
  if (props.productCode === "MAS-GB") {
    return SAVOO_EXPERIMENT_MS_TITLE;
  } else if (props.productCode === "DROO-GB") {
    return SAVOO_EXPERIMENT_DELIVEROO_TITLE;
  }
  return "";
});

function handleButtonClick() {
  trackEvent(
    "Network: User clicks on Savoo banner.",
    { customerId: props.customerId },
    props.token
  );
  window.location.href = activeSavooDiscountsUrl.value;
}

const updateSize = () => {
  mdAndDown.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener("resize", updateSize);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateSize);
});
</script>

<template>
  <div class="flex rounded-lg border border-gray-200 bg-white p-3">
    <div class="flex w-1/5 flex-col justify-center">
      <!-- Wrapper for logo only, with vertical centering -->
      <img :src="activeSavooLogoUrl" alt="logo" class="object-cover" />
    </div>
    <div class="flex w-4/5 flex-col justify-start pl-6">
      <div class="text-md mb-1 mt-1 text-gray-800">{{ activeSavooTitle }}</div>
      <button
        @click="handleButtonClick"
        :class="[
          'flex items-center justify-center rounded bg-orange-500 px-4 py-3 font-bold text-white transition duration-300 hover:bg-orange-600',
          mdAndDown ? 'w-full' : 'w-1/2',
        ]"
      >
        Get Deal
      </button>
    </div>
  </div>
</template>

import { CurrencyEnum } from "../api.generated/scion";

const currencySymbols = {
  USD: "$",
  AUD: "A$",
  BGN: "лв",
  CAD: "C$",
  CNY: "¥",
  EUR: "€",
  CHF: "₣",
  CRC: "₡",
  GBP: "£",
  HKD: "hk$",
  HRK: "kn",
  ILS: "₪",
  INR: "₹",
  IDR: "Rp",
  JPY: "¥",
  KRW: "₩",
  MXN: "MX$",
  NGN: "₦",
  PHP: "₱",
  PLN: "zł",
  PYG: "₲",
  THB: "฿",
  UAH: "₴",
  VND: "₫",
  CZK: "Kč",
  DKK: "kr.",
  HUF: "Ft",
  MYR: "RM",
  NOK: "kr",
  NZD: "NZ$",
  RON: "lei",
  SEK: "kr",
  SGD: "SGD",
  TRY: "₺",
  ZAR: "ZAR",
};

export function getCurrencySymbol(currency: CurrencyEnum): string {
  return currencySymbols[currency] || currency.toString();
}

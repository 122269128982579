<script setup lang="ts">
import { computed } from "vue";
import { ref } from "@vue/reactivity";
import { trackEvent } from "@two-ui/mixpanel";
import {
  BarcodeGraphic,
  CurrencyEnum,
  ECodeData,
  ProductData,
  RedemptionData,
  WhitelabelData,
  CustomerData,
} from "../../api.generated/scion";
import { GiftCard } from "../index";
import { getCurrencySymbol } from "../../util/currency";
import { getDaysRemaining } from "../../util/date";
import { sendEventTracking } from "../../services/redemption";
import { CODE_TOKEN, customRedeemURLs } from "../../util/product";
import { OrderItemCustomisation } from "../../api.generated/scion/models/OrderItemCustomisation";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Modal from "@two-ui/components/common/Modal.vue";
import TwoButton from "@two-components/components/TwoButton.vue";

const props = defineProps<{
  redemptionData: RedemptionData;
  token: string;
  displayBlurryCodes: boolean;
  locale: string;
}>();

const emit = defineEmits(["blurry-codes-toggle"]);

const currency = ref(props.redemptionData.currency as CurrencyEnum);
const product = ref(props.redemptionData.product as ProductData);
const whitelabel = ref(props.redemptionData.whitelabel as WhitelabelData);
const customization = ref(
  props.redemptionData.customisation as OrderItemCustomisation
);
const customer = ref(props.redemptionData.customer as CustomerData);
const showConfirmSpendModal = ref(false);

function print() {
  window.print();
}

function onShowCodesCTA() {
  emit("blurry-codes-toggle");
  trackEvent(
    'Network: User clicks the "Show codes" CTA.',
    {
      customerId: customer.value?.hashedId,
    },
    props.token
  );
  onCloseClick();
}

function revealCardDetails() {
  showConfirmSpendModal.value = true;
  sendEventTracking(props.token, "PRE_FULFILLMENT", "REVEAL_CARD_DETAILS");
}

function onCloseClick(isWaitForNow = false) {
  if (isWaitForNow) {
    sendEventTracking(props.token, "PRE_FULFILLMENT", "WAIT_FOR_NOW");
  }
  showConfirmSpendModal.value = false;
}

const eCode = computed(() => props.redemptionData.eCode as ECodeData);
const barcodeGraphic = computed(
  () => props.redemptionData.barcodeGraphic as BarcodeGraphic
);
const customRedeemURL = computed(() => {
  const customURL = customRedeemURLs[product.value.code];

  if (!customURL) return;

  return customURL.replace(CODE_TOKEN, eCode.value.code as string);
});

const formattedExpiryDate = computed(() => {
  const expiryDate = new Date(eCode.value.expiryDate);
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return expiryDate.toLocaleDateString(userLocale, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
});
</script>

<template>
  <div class="m-auto flex flex-col items-center">
    <img
      v-if="customization?.logoUrl"
      class="mb-4 rounded-2xl"
      :src="customization?.logoUrl"
      alt="Custom logo"
    />

    <h1 class="font-f37lineca mb-5 text-center text-xl font-semibold">
      {{ $t("message.gift_card", { msg: product.displayName }) }}
    </h1>

    <span class="font-f37lineca mb-5 rounded-lg bg-grey-300 px-3 py-1 text-xl">
      {{ getCurrencySymbol(currency) }}{{ redemptionData.value }}
    </span>

    <img
      class="mb-4 max-w-[200px] rounded-lg"
      :src="whitelabel.cardImageUrl"
      alt="Gift Card banner"
    />

    <div v-if="displayBlurryCodes" class="mb-4 flex flex-col items-center">
      <div
        class="mb-4 flex w-full items-start justify-between rounded-lg bg-grey-50 p-5 sm:w-[327px]"
      >
        <div class="w-1/2 text-xs text-[#333]">
          <p class="mb-1 font-bold">{{ $t("message.code_number") }}</p>
          <p class="text-sm text-black">**** **** **** ****</p>
        </div>
        <div class="w-1/2 pl-8 text-xs text-[#333]">
          <p class="mb-1 font-bold">{{ $t("message.pin") }}</p>
          <p class="text-sm text-black">******</p>
        </div>
      </div>
      <div class="flex gap-4 rounded-xl bg-yellow-50 p-6 sm:items-center">
        <p class="h-[40px] rounded-[40px] bg-yellow-200 px-3 py-2">
          <font-awesome-icon :icon="['far', 'triangle-exclamation']" />
        </p>
        <p class="text-base font-normal">
          {{ $t("message.security_message") }}
        </p>
      </div>
    </div>
    <div
      v-else
      class="mb-4 w-full rounded-2xl bg-grey-300 px-4 py-6 pb-0 sm:w-[328px]"
    >
      <!-- TODO: Check if it's a POS product, otherwise render the Regular ECode component -->
      <!-- <img
        v-if="displayBlurryCodes"
        src="@workspace/assets/images/payout-link-blurred-code.png"
        alt="blurry-code"
      /> -->
      <GiftCard
        :eCode="eCode"
        :product="product"
        :barcodeGraphic="barcodeGraphic"
        :locale="locale"
      ></GiftCard>
    </div>
    <p
      v-if="
        !displayBlurryCodes &&
        eCode?.expiryDate &&
        product?.countryCode !== 'CA'
      "
      class="mb-4 w-full text-center text-sm font-normal"
      data-testid="code-expiration-message"
    >
      {{
        $t("message.expiry_date", {
          time: getDaysRemaining(eCode.expiryDate),
          days: formattedExpiryDate,
        })
      }}
    </p>

    <div class="flex w-full justify-center sm:w-[328px] print:hidden">
      <button
        v-if="displayBlurryCodes"
        @click="revealCardDetails"
        target="_blank"
        class="flex h-[44px] flex-1 items-center justify-center rounded-xl p-4 text-center text-white drop-shadow-md hover:drop-shadow-lg sm:max-w-[327px]"
        :style="{
          backgroundColor:
            redemptionData.customisation?.primaryColour || 'black',
        }"
        data-testId="show-codes-cta"
      >
        <!-- <span>{{ $t("message.show_codes") }}</span> -->
        <span class="text-sm font-bold">{{
          $t("message.reveal_card_details")
        }}</span>
      </button>
      <a
        v-else
        :href="customRedeemURL || whitelabel.brandUrl"
        target="_blank"
        @click="
          sendEventTracking(props.token, 'POST_FULFILMENT', 'SPEND_ONLINE')
        "
        class="flex h-[44px] flex-1 items-center justify-center rounded-xl p-4 text-center text-white drop-shadow-md hover:drop-shadow-lg"
        :style="{
          backgroundColor:
            redemptionData.customisation?.primaryColour || 'black',
        }"
      >
        <span v-if="customRedeemURL">{{ $t("message.apply_to_account") }}</span>
        <span v-else>{{ $t("message.spend_online") }}</span>
      </a>
      <button
        v-if="!displayBlurryCodes"
        @click="print"
        class="ml-2 flex h-[44px] items-center justify-center rounded-xl bg-grey-app p-4 md:p-6"
        type="button"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9V2H18V9"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V16C22 16.5304 21.7893 17.0391 21.4142 17.4142C21.0391 17.7893 20.5304 18 20 18H18"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 14H18V22H6V14Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <a
      v-if="!displayBlurryCodes"
      href="#how-to"
      class="mt-4 font-semibold print:hidden"
      @click="
        sendEventTracking(
          props.token,
          'POST_FULFILMENT',
          'HOW_DO_I_USE_MY_GIFT_CARD'
        )
      "
    >
      {{ $t("message.how_to_use_my_gift_card") }}
    </a>
    <Modal
      :closable="true"
      :show="showConfirmSpendModal"
      @close="onCloseClick"
      :title="$t('message.ready_to_spend')"
      containerClass="sm:max-w-[626px] full-minus-32 mx-4"
    >
      <template #body>
        <div>
          <p class="text-base font-normal text-black">
            {{ $t("message.security_message") }}
          </p>
        </div>
      </template>
      <template #footer>
        <TwoButton data-testId="wait-for-now" @click="onCloseClick(true)">
          {{ $t("message.wait_for_now") }}
        </TwoButton>
        <TwoButton
          class="btn-black"
          data-testId="ready-to-spend"
          :style="{
            backgroundColor:
              redemptionData.customisation?.primaryColour || 'black',
          }"
          @click="onShowCodesCTA"
        >
          {{ $t("message.i_am_ready_to_spend") }}
        </TwoButton>
      </template>
    </Modal>
  </div>
</template>

<style scoped>
.shawdow-redeem {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.13), 0px 9px 19px rgba(0, 0, 0, 0.3);
}
::v-deep .full-minus-32 {
  width: calc(100% - 32px);
}
</style>

export type I18nMessages = {
  [locale: string]: {
    error: {
      [key: string]: string;
    };
    message: {
      [key: string]: string;
    };
  };
};

export const SEND_TRANSLATION = {
  BASE_URL: import.meta.env.VITE_SPEND_TRANSLATION_BASE_URL,
  DEFAULT: import.meta.env.VITE_SPEND_TRANSLATION_DEFAULT,
};

export async function tryFetchTranslationMessages(
  path_prefix: string = "",
  path: string = SEND_TRANSLATION.DEFAULT
): Promise<I18nMessages> {
  if (path_prefix) {
    path_prefix = `${path_prefix}-`;
  }

  try {
    const fetchMessages = await fetch(
      `${SEND_TRANSLATION.BASE_URL}${path_prefix}${path}`,
      {
        cache: "no-store",
      }
    );

    // Check if the status is not 200, and return an empty object
    if (fetchMessages.status !== 200) {
      console.warn(
        `Failed to fetch translation messages. Status: ${fetchMessages.status}`
      );
      return {};
    }

    // Return the JSON response if the fetch was successful
    return await fetchMessages.json();
  } catch (error) {
    // Handle any network or unexpected errors during the fetch
    console.warn(
      "An error occurred while fetching translation messages:",
      error
    );
    return {};
  }
}

export function mergeI18nMessages(
  base: I18nMessages,
  override?: I18nMessages
): I18nMessages {
  // If override is not provided, return base as is
  if (!override) {
    return base;
  }

  const result: I18nMessages = { ...base };

  // Iterate over locales in override
  for (const locale in override) {
    if (override.hasOwnProperty(locale)) {
      if (base[locale]) {
        // Merge 'error' and 'message' fields for locales present in both base and override
        result[locale] = {
          error: {
            ...base[locale].error,
            ...override[locale].error, // override's error messages take precedence
          },
          message: {
            ...base[locale].message,
            ...override[locale].message, // override's regular messages take precedence
          },
        };
      } else {
        // If locale doesn't exist in base, just copy it from override
        result[locale] = override[locale];
      }
    }
  }

  return result;
}

import {
  ApiError,
  RedemptionResponse,
  WalletService,
} from "../api.generated/scion";
import { OpenAPI } from "../api.generated/scion";
import {
  FulfilmentClient,
  stageType,
  userActionType,
} from "../util/FulfilmentClient";
import { splitter } from "../util/splitter";

const trafficSplit = import.meta.env.VITE_SPL_SENT_TO_FULFILMENT_SERVICE_RATE;
const callFulfilmentService = splitter(trafficSplit);

export async function getSpendDetails(
  token: string,
  exp: string | null,
  fulfil: boolean | null
): Promise<RedemptionResponse | undefined> {
  const { listOfBaseUrls, redemptionService } = getServiceAndBasePath();

  for (const [index, baseUrl] of listOfBaseUrls.entries()) {
    try {
      // Do not remove the `await` from here. We want to catch the error
      // inside this method. Check the comment block below on why.
      OpenAPI.BASE = baseUrl;

      const validExp = exp === "1" || exp === "2" ? exp : undefined;
      const validFulfil =
        fulfil === true || fulfil === false ? fulfil.toString() : undefined;
      return await redemptionService.redeemECode(token, validExp, validFulfil);
    } catch (e) {
      // If there's another url to be attempted, log the error and try the next URL
      if (index < listOfBaseUrls.length - 1) {
        console.error(e);
        continue;
      }
      // If there's a different kind of error, throw it to be handled by the caller
      throw e;
    }
  }
}

export async function sendEventTracking(
  token: string,
  stage: stageType,
  userAction: userActionType,
  options?: any
) {
  const { listOfBaseUrls, redemptionService } = getServiceAndBasePath(true);

  for (const index in listOfBaseUrls) {
    try {
      // Do not remove the `await` from here. We want to catch the error
      // inside this method. Check the comment block below on why.
      OpenAPI.BASE = listOfBaseUrls[index];
      // @ts-ignore
      if (redemptionService.sentEventTracking) {
        // @ts-ignore
        return await redemptionService.sentEventTracking(
          token,
          stage,
          userAction,
          options
        );
      }
      return new Promise((resolve) => {
        resolve(true);
      });
    } catch (e) {
      // If there's an API error with a status code 400 or above, log it and try the next URL
      if ((e as ApiError).status >= 400) {
        console.error(e);
        continue;
      }
      // If there's a different kind of error, throw it to be handled by the caller
      throw e;
    }
  }
}

function getServiceAndBasePath(fullTrafficFulfilmentService = false): {
  listOfBaseUrls: string[];
  redemptionService: typeof FulfilmentClient | typeof WalletService;
} {
  const isFulfilmentEnabledforSpl = import.meta.env
    .VITE_ENABLE_FULFILMENT_SERVICE_FOR_SPL;

  // TODO: Remove this check once the playground env is deployed!
  const isPlayground = window.location.href.includes("playground");

  let commaSeparatedBaseUrls, redemptionService;
  if (
    (isFulfilmentEnabledforSpl && callFulfilmentService && !isPlayground) ||
    (fullTrafficFulfilmentService && !isPlayground)
  ) {
    commaSeparatedBaseUrls = import.meta.env
      .VITE_FULFILMENT_SERVICE_BASE_URL as string;
    redemptionService = FulfilmentClient;
  } else {
    commaSeparatedBaseUrls = import.meta.env.VITE_SCION_BASE_URL as string;
    redemptionService = WalletService;
  }

  const listOfBaseUrls = commaSeparatedBaseUrls.split(",");
  return { listOfBaseUrls, redemptionService };
}

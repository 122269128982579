export function getDaysRemaining(expiryDateString: string) {
  const expiryDate = new Date(expiryDateString);
  const now = new Date();

  const milliSecondsRemaining = expiryDate.valueOf() - now.valueOf();
  const milliSecondsPerMinute = 60000;
  const minutesPerHour = 60;
  const hoursPerDay = 24;
  const daysRemaining =
    milliSecondsRemaining /
    (milliSecondsPerMinute * minutesPerHour * hoursPerDay);

  return Math.floor(daysRemaining);
}

export const barcodeFormat: { [key: string]: string } = {
  CODE_128: "code128",
  CODE_39: "code39",
  QR: "qrcode",
  EAN13: "ean13",
  PDF417: "pdf417",
  ITF: "itf14",
  DATA_MATRIX: "datamatrix",
};

export const barcodeSize: { [key: string]: number[] } = {
  CODE_128: [100, 40],
  CODE_39: [100, 30],
  EAN13: [100, 50],
  QR: [100, 100],
  PDF417: [100, 40],
  ITF: [100, 40],
  DATA_MATRIX: [100, 100],
};

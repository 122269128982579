/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Same as ProductRedeemLocationEnum but without ALL, intended to be used in a list.
 * Needed for product FAQs.
 * TODO, Remove ProductRedeemLocationEnum and replace with this version
 */
export enum ProductSpendTypeEnum {
  IN_STORE = "in-store",
  ONLINE = "online",
}
